import { Modal, Space } from "antd";
import { ReactNode, useState } from "react";
import CommonIcons from "../../../assets/icons";
import ButtonUI from "../Button/ButtonUI";

interface IProps {
  title?: string;
  open?: boolean;
  children?: ReactNode;
  toggle: () => void;
  className?: string;
  width?: number;
  onConfirm?: () => void;
  hiddenAction?: boolean;
  type?: string;
}

export default function ModalUI(props: IProps) {
  //! state
  const {
    title,
    open,
    children,
    toggle,
    className,
    width,
    onConfirm,
    hiddenAction,
    type,
    ...res
  } = props;

  //! render
  if (type === "error") {
    return (
      <Modal
        open={open}
        title={title ?? "Bạn có chắc chắn muốn xoá ?"}
        onCancel={toggle}
        classNames={{
          footer: "flex justify-center items-center",
          header: "text-center",
          content: "flex flex-col items-center gap-4",
        }}
        centered
        footer={
          <Space size={"middle"}>
            <ButtonUI onClick={onConfirm}>Xác nhận</ButtonUI>
            <ButtonUI onClick={toggle} type="primary" ghost danger>
              Hủy
            </ButtonUI>
          </Space>
        }
        onOk={onConfirm}
      >
        <CommonIcons.WarningOutlined
          className="text-red-500 text-2xl"
          style={{ fontSize: "5rem" }}
        />
      </Modal>
    );
  }

  return (
    <Modal
      title={title}
      open={open}
      className={`${className}`}
      width={width ?? 600}
      onCancel={onConfirm}
      footer={
        !hiddenAction && (
          <Space>
            <ButtonUI onClick={onConfirm}></ButtonUI>
            <ButtonUI onClick={toggle} type="primary" ghost danger></ButtonUI>
          </Space>
        )
      }
      {...res}
    >
      {children}
    </Modal>
  );
}
