import { Form, FormRule } from "antd";
import { ReactNode } from "react";

interface IProps {
  name: string;
  label?: string;
  component: ReactNode;
  hasFeedback?: boolean;
  rules?: FormRule[];
}
export default function FormFieldUI(props: IProps) {
  //! state
  const { name, label, component, hasFeedback, rules, ...res } = props;
  //! function
  //! render
  return (
    <Form.Item
      name={name}
      hasFeedback={hasFeedback}
      label={label}
      rules={rules}
      className="py-2"
      {...res}
    >
      {component}
    </Form.Item>
  );
}
