import { useInfiniteQuery } from "react-query";
import queryKey from "../../constants/apiKey";
import planService from "../../services/planService";

export const useGetInfinitePlan = (filters: any) => {
  return useInfiniteQuery(
    [queryKey.infinitePlan, filters],
    async ({ pageParam = 1 }) => {
      const plans = await planService.getPlan({
        ...filters,
        page: pageParam,
      });
      return plans;
    },
    {
      getNextPageParam: (lastPage, pages) => {
        // console.log(lastPage);
      },
    }
  );
};
