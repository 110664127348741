import {
  MapContainer,
  TileLayer,
  ZoomControl,
  GeoJSON,
  LayersControl,
  FeatureGroup,
  Polyline,
  WMSTileLayer,
} from "react-leaflet";
import { LatLngExpression } from "leaflet";

import _ from "lodash";
import "./leaflet.css";
import { urlMAP } from "../../../constants/api";

interface IProps {
  maxZoom?: number;
  minZoom?: number;
  zoom?: number;
  zoomControl?: boolean;
  className?: string;
  dataGeoJson?: any;
  optionLayer?: boolean;
  polylines?: any;
  markers?: { latitude: number; longitude: number }[];
  renderPopupMarkers?: any;
  uniqueId?: string;
  defaultAllJSON?: boolean;
  hasWMS?: boolean;
  defaultHiddenSquare?: boolean;
}
export default function LeafletUI(props: IProps) {
  //! state
  const {
    zoom = 5,
    maxZoom = 20,
    minZoom = 4,
    zoomControl = true,
    dataGeoJson,
    optionLayer,
    polylines,
    renderPopupMarkers,
    uniqueId,
    defaultAllJSON,
    hasWMS,
    defaultHiddenSquare,
  } = props;
  const position: LatLngExpression = [17.516525710679844, 106.68387626810897];

  const limeOptions = { color: "rgba(76, 194, 255, 1)" };
  //! function
  //! render

  const onEachFeature = (feature: any, layer: any) => {
    if (feature.properties && feature.properties.id) {
      // Tính trung tâm của polygon
      const center = layer.getBounds().getCenter();
      layer
        .bindTooltip(feature.properties.id, {
          permanent: true,
          direction: "center",
          className: "layer-custom",
        })
        .openTooltip(center);
    }
  };
  const onEachFeatureLarge = (feature: any, layer: any) => {
    if (feature.properties && feature.properties.id) {
      // Tính trung tâm của polygon
      const center = layer.getBounds().getCenter();
      layer
        .bindTooltip(feature.properties.id, {
          permanent: true,
          direction: "center",
          className: "layer-custom-large",
        })
        .openTooltip(center);
    }
  };
  const dataPolylines = polylines?.map((item: any) => {
    return [Number(item?.latitude), Number(item?.longitude)];
  });
  return (
    <MapContainer
      key={uniqueId && uniqueId}
      center={position}
      zoom={zoom}
      maxZoom={maxZoom}
      minZoom={minZoom}
      zoomControl={false}
      scrollWheelZoom={true}
      doubleClickZoom={false}
      attributionControl={false}
      style={{
        width: "100%",
        height: "100%",
        minHeight: 600,
        marginBottom: "12px",
      }}
    >
      <TileLayer url="https://mt1.google.com/vt/lyrs=s&hl=pl&&x={x}&y={y}&z={z}" />

      {zoomControl && <ZoomControl position="bottomleft" />}
      {!_.isEmpty(polylines) && renderPopupMarkers}

      {!_.isEmpty(polylines) && (
        <Polyline
          pathOptions={limeOptions}
          className="water-flow"
          positions={dataPolylines}
        />
      )}

      {optionLayer && (
        <LayersControl position="topright">
          <LayersControl.Overlay
            checked={!hasWMS && !defaultHiddenSquare}
            name="Ô vuông cơ bản"
          >
            <FeatureGroup>
              {!_.isEmpty(dataGeoJson?.basicSquare) && (
                <GeoJSON
                  data={dataGeoJson.basicSquare}
                  style={{ color: "#e53935", opacity: 0.5 }}
                  onEachFeature={onEachFeature}
                />
              )}
            </FeatureGroup>
          </LayersControl.Overlay>

          <LayersControl.Overlay
            checked={!hasWMS && !defaultHiddenSquare}
            name="Ô vuông lớn"
          >
            <FeatureGroup>
              {!_.isEmpty(dataGeoJson?.largeSquare) && (
                <GeoJSON
                  data={dataGeoJson.largeSquare}
                  onEachFeature={onEachFeatureLarge}
                />
              )}
            </FeatureGroup>
          </LayersControl.Overlay>
          {hasWMS && (
            <LayersControl.Overlay
              checked={false}
              name="Ô vuông vừa và tưởng tượng"
            >
              <FeatureGroup>
                {
                  <WMSTileLayer
                    layers="khong-quan:medium_imaginary_layer"
                    url={`${urlMAP}`}
                    transparent={true}
                    format="image/png"
                    minZoom={10}
                    maxZoom={18}
                    opacity={0.3}
                  />
                }
              </FeatureGroup>
            </LayersControl.Overlay>
          )}
        </LayersControl>
      )}
    </MapContainer>
  );
}
