export const dataPlane = [
  { id: 1, title: "Bản đồ bay 1", data: "04/04/2024", creator: "duylb" },
  { id: 2, title: "Bản đồ bay 2", data: "04/04/2024", creator: "duylb" },
  { id: 3, title: "Bản đồ bay 3", data: "04/04/2024", creator: "duylb" },
  { id: 4, title: "Bản đồ bay 4", data: "04/04/2024", creator: "duylb" },
  { id: 5, title: "Bản đồ bay 5", data: "04/04/2024", creator: "duylb" },
  { id: 6, title: "Bản đồ bay 5", data: "04/04/2024", creator: "duylb" },
  { id: 7, title: "Bản đồ bay 5", data: "04/04/2024", creator: "duylb" },
  { id: 8, title: "Bản đồ bay 5", data: "04/04/2024", creator: "duylb" },
  { id: 9, title: "Bản đồ bay 5", data: "04/04/2024", creator: "duylb" },
  { id: 10, title: "Bản đồ bay 5", data: "04/04/2024", creator: "duylb" },
];

export const dataChatBot = [
  {
    id: 2,
    text: "0235-2276-1422-0530-1800",
  },
  {
    id: 1,
    text: "Phương tiện đã di chuyển tới vị trí 10222,210122",
  },
];
