import React, { useCallback } from "react";
import CommonStyles from "../../UI";
import { Divider, Form } from "antd";
import { useGetTemplate } from "../../../hooks/useTemplate/useGetInfiniteTemplate";
import usePostPlan from "../../../hooks/usePlan/usePostPlan";
import { showError, showSuccess } from "../../../helpers/toast";
import { getErrorMsg } from "../../../helpers";
import { IPlan } from "../../../interfaces/IPlan/IPlan";
import { mesSuccess } from "../../../constants";
import useUpdatePlan from "../../../hooks/usePlan/useUpdatePlan";
import _ from "lodash";

interface IProps {
  handleClose: () => void;
  itemUpdate?: IPlan | null;
  setPlan?: any;
}
export default function FormAdd(props: IProps) {
  //! state
  const { handleClose, itemUpdate, setPlan } = props;
  const [form] = Form.useForm();

  const filters = {
    items_per_page: 100,
  };
  const { data: dataTemplate, isLoading: loadingTemplate } =
    useGetTemplate(filters);

  const { mutateAsync: postPlan, isLoading: loadingPlan } = usePostPlan();
  const { mutateAsync: updatePlan, isLoading: loadingUpdate } = useUpdatePlan();
  const initialValues = {
    title: itemUpdate ? itemUpdate.title : "",
    top_score: itemUpdate ? itemUpdate.top_score : "",
    template_id: itemUpdate ? itemUpdate.template_id : "",
    description: itemUpdate ? itemUpdate.description : "",
  };
  //! function
  const handleSubmit = async (values: any) => {
    try {
      if (!itemUpdate) {
        await postPlan(values, {
          onSuccess: async () => {
            showSuccess(mesSuccess);
            handleClose();
          },
        });
      } else {
        await updatePlan(
          { id: Number(itemUpdate?.id), body: values },
          {
            onSuccess: (res) => {
              showSuccess(res.data?.message ?? mesSuccess);
              handleClose();
            },
          }
        );
      }
    } catch (error) {
      showError(getErrorMsg(error));
    }
  };

  const parseOption = useCallback(() => {
    if (dataTemplate) {
      const options: any = [];
      dataTemplate?.data?.data?.map((template: any) => {
        options.push({ label: template.title, value: template.id });
      });
      return options;
    }
    return [];
  }, [dataTemplate]);

  const afterOnchange = () => {
    const templateValue = form.getFieldValue("template_id");
    const templateSelected = _.find(
      dataTemplate?.data.data,
      (item) => item.id === templateValue
    );
    setPlan({ template_id: templateSelected.id });
  };
  //! render
  return (
    <div className="col-span-1 border-r px-3 mt-3">
      <div className="flex justify-between">
        <CommonStyles.TypographyUI className="font-bold text-lg">
          {itemUpdate ? "Cập nhật lịch trình bay" : "Thêm mới lịch trình bay"}
        </CommonStyles.TypographyUI>
      </div>

      <Divider />

      <div className="flex flex-col gap-y-2">
        <Form
          form={form}
          layout="vertical"
          initialValues={initialValues}
          onFinish={handleSubmit}
        >
          <CommonStyles.FormFieldUI
            name="title"
            label="Tiêu đề"
            rules={[{ required: true, message: "Tiều đề không được trống!" }]}
            component={
              <CommonStyles.InputUI
                className="bg-gray-100"
                maxLength={50}
                placeholder={"Nhập tiêu đề"}
              />
            }
          />

          <CommonStyles.FormFieldUI
            name="top_score"
            label="Chỉ số tốp"
            rules={[
              { required: true, message: "Chỉ số tốp không được trống!" },
            ]}
            component={
              <CommonStyles.InputUI
                className="bg-gray-100"
                maxLength={50}
                placeholder={"Nhập chỉ số tốp"}
              />
            }
          />

          <CommonStyles.FormFieldUI
            name="template_id"
            label="Mẫu mã hoá"
            rules={[
              { required: true, message: "Mẫu mã hoá không được trống!" },
            ]}
            component={
              <CommonStyles.SelectUI
                variant="filled"
                className="bg-gray-100"
                options={parseOption()}
                afterOnchange={afterOnchange}
              />
            }
          />

          <CommonStyles.FormFieldUI
            name="description"
            label="Ghi chú"
            rules={[{ required: true, message: "Ghi chú không được trống!" }]}
            component={
              <CommonStyles.TextArea
                rows={7}
                maxLength={50}
                placeholder={"Nhập nội dung"}
                className="bg-gray-100"
              />
            }
          />

          <div className=" flex justify-end gap-3 pt-4">
            <CommonStyles.ButtonUI
              className="bg-gray-100 text-black px-10"
              onClick={handleClose}
            >
              Hủy
            </CommonStyles.ButtonUI>

            <CommonStyles.ButtonUI
              action="submit"
              className="bg-black text-white px-10"
              loading={loadingPlan}
            >
              Lưu
            </CommonStyles.ButtonUI>
          </div>
        </Form>
      </div>
    </div>
  );
}
