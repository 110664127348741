import { Typography } from "antd";
import { ReactNode } from "react";

const { Text, Paragraph, Title, Link } = Typography;
interface IProps {
  typographyType?: "text" | "title" | "paragraph" | "link";
  type?: "secondary" | "success" | "warning" | "danger";
  underline?: boolean;
  italic?: boolean;
  strong?: boolean;
  children?: string | ReactNode;
  className?: string;
  level?: 1 | 2 | 3 | 4 | 5;
}
export default function TypographyUI(props: IProps) {
  //! state
  const {
    typographyType = "text",
    type,
    underline,
    italic,
    strong,
    children,
    className,
    level,
  } = props;
  //! function
  //! render
  if (typographyType === "title") {
    return <Title level={level}> {children}</Title>;
  }
  return (
    <Text
      type={type}
      underline={underline}
      italic={italic}
      strong={strong}
      className={className}
    >
      {children}
    </Text>
  );
}
