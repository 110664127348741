export const validateLarge = (_: any, value: any) => {
  if (Object.keys(value).length === 0) {
    return Promise.reject(new Error(`Ô vuông lớn phải có giá trị!`));
  }
  return Promise.resolve();
};
export const validateBasic = (_: any, value: any) => {
  if (Object.keys(value).length === 0) {
    return Promise.reject(new Error(`Ô vuông nhỏ phải có giá trị!`));
  }
  return Promise.resolve();
};
