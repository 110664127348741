import { useMutation } from "react-query";
import templateService from "../../services/templateService";

export const useGetLargeSquare = () => {
  return useMutation(templateService.getLargeSquare);
};

export const useGetBasicSquare = () => {
  return useMutation(templateService.getBasicSquare);
};
