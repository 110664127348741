import map from "./map.svg";
import templa from "./templa.svg";
import logo from "./logo.svg";
import user from "./user.svg";
import bot from "./bot.svg";
import position from "./position.svg";
import mapActive from "./mapActive.svg";
import templaActive from "./templaActive.svg";

const CommonImages = {
  map: map,
  templa: templa,
  logo: logo,
  user: user,
  bot: bot,
  position: position,
  mapActive: mapActive,
  templaActive: templaActive,
};

export default CommonImages;
