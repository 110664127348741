import { useQuery } from "react-query";
import queryKey from "../../constants/apiKey";
import planService from "../../services/planService";

export const useGetDetailPlan = (id: number, options?: any) => {
  return useQuery(
    [queryKey.detailPlan],
    () => planService.getDetailPlan(id),
    options
  );
};
