import { API_ASR } from "../constants/api";
import httpService from "./httpService";

class AsrService {
  speech2Text(body: any) {
    return httpService.post(API_ASR, body);
  }
}

export default new AsrService();
