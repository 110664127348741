import CommonStyles from "../../../components/UI";
import CommonIcons from "../../../assets/icons";
import { useHandleNavigate } from "../../../hooks/useHandleNavigate";
import Chatbot from "../../../components/maps/detailMaps/Chatbot";
import { useLocation, useParams } from "react-router-dom";
import moment from "moment";
import React, { useCallback, useState } from "react";
import { useGetDetailPlan } from "../../../hooks/usePlan/useGetDetailPlan";
import _ from "lodash";
import { queryClient } from "../../../App";
import queryKey from "../../../constants/apiKey";
import { Marker, Popup } from "react-leaflet";
import IconLeaf from "../../../components/UI/IconLeaf/IconLeaf";
import { IRoute } from "../../../interfaces/IChat";
import L from "leaflet";
import CommonImages from "../../../assets/images";
import styles from "./style.module.css";

export default function DetailMap() {
  //! state
  const { handleBack } = useHandleNavigate();
  const location = useLocation();
  const parmas = useParams();
  const template = location?.state;
  const { data, isLoading } = useGetDetailPlan(Number(parmas.id));
  const [polylines, setPolylines] = useState<IRoute[]>([]);
  const detailPlan = data?.data?.data;
  //! function
  React.useEffect(() => {
    if (!_.isEmpty(data?.data?.data)) {
      setPolylines(detailPlan.route);
    }
  }, [isLoading]);
  React.useEffect(() => {
    return () => {
      queryClient.resetQueries({ queryKey: queryKey.detailPlan, exact: true });
    };
  }, []);
  const handleFinish = () => {
    // showSuccess("Pending ...");
  };
  //! render
  const renderPopupmarkers = useCallback(() => {
    return polylines?.map((item: IRoute, index: number) => {
      const numberIcon = L.divIcon({
        className: `${styles.numberIcon}`,
        iconAnchor: [18, 45],
        iconSize: [35, 60],
        html: `${index + 1}`,
      });
      return (
        <Marker
          position={[Number(item?.latitude), Number(item?.longitude)]}
          key={index}
          icon={numberIcon}
        >
          <Popup className="!p-3" closeButton={false}>
            <div className="flex flex-col">
              <CommonStyles.TypographyUI>
                {`Vĩ độ: ${item?.latitude}`}
              </CommonStyles.TypographyUI>
              <CommonStyles.TypographyUI>
                {`Kinh độ: ${item?.longitude}`}
              </CommonStyles.TypographyUI>
              <CommonStyles.TypographyUI>
                {`Điểm cao nhất: ${item?.top_score}`}
              </CommonStyles.TypographyUI>
              <CommonStyles.TypographyUI>
                {`Thời gian: ${item?.time_at}`}
              </CommonStyles.TypographyUI>
              <CommonStyles.TypographyUI>
                {`Mục tiêu: ${item?.target}`}
              </CommonStyles.TypographyUI>
              <CommonStyles.TypographyUI>
                {`Loại mục tiêu: ${item?.target_type}`}
              </CommonStyles.TypographyUI>
              <CommonStyles.TypographyUI>
                {`Chiều cao mục tiêu: ${item?.target_height}`}
              </CommonStyles.TypographyUI>
            </div>
          </Popup>
        </Marker>
      );
    });
  }, [polylines?.length]);

  return (
    <div className="h-full relative">
      <CommonStyles.ButtonUI
        className="bg-white text-black rounded-lg absolute z-1000 top-5 left-5"
        Icons={<CommonIcons.LeftOutlined />}
        size="large"
        onClick={handleBack}
      >
        Trở về
      </CommonStyles.ButtonUI>

      <CommonStyles.LeafletUI
        polylines={polylines}
        renderPopupMarkers={renderPopupmarkers()}
        optionLayer
        hasWMS
        dataGeoJson={{
          largeSquare: template?.template.large_square,
          basicSquare: template?.template.basic_square,
        }}
      />

      <div
        className="absolute grid gap-3 z-1000 bottom-5 right-5"
        style={{ minWidth: 350 }}
      >
        <CommonStyles.CardUI
          title="Thông tin bay"
          extra={
            <CommonStyles.ButtonUI onClick={handleFinish} className="bg-black">
              Kết thúc
            </CommonStyles.ButtonUI>
          }
        >
          <div
            className="flex flex-col p-3 rounded-sm"
            style={{ background: "rgba(235, 237, 238, 1)" }}
          >
            <CommonStyles.TypographyUI className="text-lg">
              {detailPlan?.title}
            </CommonStyles.TypographyUI>
            <CommonStyles.TypographyUI>
              Mẫu sử dụng: {template?.template?.title}
            </CommonStyles.TypographyUI>
            <CommonStyles.TypographyUI>{`Chỉ số tốp: ${detailPlan?.top_score}`}</CommonStyles.TypographyUI>
            <CommonStyles.TypographyUI>{`Ngày tạo: ${moment(
              detailPlan?.created_at
            ).format("DD/MM/YYYY")}`}</CommonStyles.TypographyUI>
            <CommonStyles.TypographyUI>{`Ghi chú: ${detailPlan?.description}`}</CommonStyles.TypographyUI>
          </div>
        </CommonStyles.CardUI>

        <Chatbot setPolylines={setPolylines} />
      </div>
    </div>
  );
}
