import { Popover } from "antd";
import React from "react";

interface IProps {
  children: React.ReactNode;
  title?: React.ReactNode;
  content?: React.ReactNode;
  trigger?: "hover" | "focus" | "click";
  placement?:
    | "top"
    | "left"
    | "right"
    | "bottom"
    | "topLeft"
    | "topRight"
    | "bottomLeft"
    | "bottomRight"
    | "leftTop"
    | "leftBottom"
    | "rightTop"
    | "rightBottom";

  open?: boolean;
  handleOpenChange?: () => void;
}
const PopoverUI = (props: IProps) => {
  //! state
  const {
    title,
    content,
    trigger = "click",
    placement = "topRight",
    children,
    open,
    handleOpenChange,
  } = props;
  //! function
  //! render
  return (
    <Popover
      open={open}
      onOpenChange={handleOpenChange}
      placement={placement}
      title={title}
      content={content}
      trigger={trigger}
    >
      {children}
    </Popover>
  );
};

export default PopoverUI;
