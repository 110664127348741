import { useMutation } from "react-query";
import planService from "../../services/planService";

interface IUpdate {
  id: number;
  body: {
    title: string;
    top_score: string;
    template_id: number;
    description: string;
  };
}
export const useUpdatePlan = () => {
  return useMutation({
    mutationFn: ({ id, body }: IUpdate) => planService.updatePlan(id, body),
  });
};

export default useUpdatePlan;
