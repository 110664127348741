import { useNavigate } from "react-router-dom";

export const useHandleNavigate = () => {
  const navigate = useNavigate();
  const handleBack = () => {
    return navigate(-1);
  };
  const handleNavigate = (url: string) => {
    return navigate(url);
  };

  return {
    handleBack,
    handleNavigate,
  };
};
