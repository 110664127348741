import { API_PLAN } from "../constants/api";
import { parseQueryString } from "../helpers";
import httpService from "./httpService";

class PlanService {
  getPlan(query?: any) {
    return httpService.get(`${API_PLAN}?${parseQueryString(query)}`);
  }
  postPlan(body: any) {
    return httpService.post(API_PLAN, body);
  }
  getDetailPlan(id: number) {
    return httpService.get(`${API_PLAN}/${id}`);
  }
  deletePlan(id: number) {
    return httpService.delete(`${API_PLAN}/${id}`);
  }
  updatePlan(id: number, body: any) {
    return httpService.patch(`${API_PLAN}/${id}`, body);
  }
}

export default new PlanService();
