import { useInfiniteQuery, useQuery } from "react-query";
import queryKey from "../../constants/apiKey";
import templateService from "../../services/templateService";

const useGetInfiniteTemplate = (filters: any) => {
  return useInfiniteQuery(
    [queryKey.infiniteTemplate, filters],
    async ({ pageParam = 1 }) => {
      const templates = await templateService.getTemplate({
        ...filters,
        page: pageParam,
      });
      return templates;
    },
    {
      cacheTime: 0,
      staleTime: 0,
      getNextPageParam: (lastPage, pages) => {
        // console.log(lastPage);
      },
    }
  );
};
export const useGetTemplate = (filters: any) => {
  return useQuery(
    [queryKey.template],
    () => {
      return templateService.getTemplate({
        ...filters,
      });
    },
    { cacheTime: 0, staleTime: 0 }
  );
};

export default useGetInfiniteTemplate;
