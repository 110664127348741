import CommonStyles from "..";
import CommonIcons from "../../../assets/icons";
import useToggle from "../../../hooks/useToggle";
import ButtonUI from "../Button/ButtonUI";
import PopoverUI from "../PopoverUI/PopoverUI";
interface IProps {
  actionUpdate?: () => void;
  actionDelete?: () => void;
  open?: boolean;
}
const ButtonAction = (props: IProps) => {
  //! state
  const { actionUpdate, actionDelete } = props;
  const { toggle, open, shouldRender } = useToggle();
  //! function
  const handleOpen = () => {
    toggle();
  };
  //! rendeer
  const renderAction = () => {
    return (
      <div className="flex flex-col gap-2">
        {actionUpdate && (
          <ButtonUI
            onClick={actionUpdate}
            type="text"
            className="text-start"
            Icons={<CommonIcons.EditOutlined style={{ color: "#ffca28" }} />}
          >
            Cập nhật
          </ButtonUI>
        )}
        {actionDelete && (
          <ButtonUI
            onClick={() => {
              actionDelete();
              toggle();
            }}
            type="text"
            className="text-start"
            Icons={<CommonIcons.DeleteOutlined style={{ color: "#e53935" }} />}
          >
            Xoá
          </ButtonUI>
        )}
      </div>
    );
  };
  return (
    <PopoverUI
      placement="right"
      content={renderAction()}
      open={open}
      handleOpenChange={() => {
        toggle() as any;
      }}
    >
      <CommonStyles.TooltipUI title="Hành động" placement="left">
        <CommonStyles.ButtonUI
          onClick={handleOpen}
          Icons={<CommonIcons.MoreOutlined />}
          type="text"
        />
      </CommonStyles.TooltipUI>
    </PopoverUI>
  );
};

export default ButtonAction;
