import { API_TEMPLATE } from "../constants/api";
import { parseQueryString } from "../helpers";
import httpService from "./httpService";

class TemplateService {
  postTemplate(body: any) {
    return httpService.post(API_TEMPLATE, body);
  }
  getLargeSquare(body: any) {
    return httpService.post(`${API_TEMPLATE}/large-square`);
  }
  getBasicSquare(body: any) {
    return httpService.post(`${API_TEMPLATE}/basic-square`);
  }
  getTemplate(query?: any) {
    return httpService.get(`${API_TEMPLATE}?${parseQueryString(query)}`);
  }
  deleteTemplate(id: number) {
    return httpService.delete(`${API_TEMPLATE}/${id}`);
  }
  updateTemplate(id: number, body: any) {
    return httpService.patch(`${API_TEMPLATE}/${id}`, body);
  }
}
export default new TemplateService();
