import CommonStyles from "../../UI";
import { Divider, Form } from "antd";
import usePostTemplate from "../../../hooks/useTemplate/usePostTemplate";
import {
  useGetBasicSquare,
  useGetLargeSquare,
} from "../../../hooks/useTemplate/useGetSquare";
import { showError, showSuccess } from "../../../helpers/toast";
import { getErrorMsg } from "../../../helpers";
import { useEffect } from "react";
import { ITemplate } from "../../../interfaces/ITemplate/Itemplate";
import _ from "lodash";
import { mesSuccess } from "../../../constants";
import { validateBasic, validateLarge } from "./validate";
import useUpdateTemplate from "../../../hooks/useTemplate/useUpdateTemplate";

interface IProps {
  handleClose: () => void;
  handleLarge: any;
  handleBasic: any;
  itemUpdate: ITemplate | null;
  refetchTemplates: any;
}
export default function FormAddTemplate(props: IProps) {
  //! state
  const {
    handleClose,
    handleBasic,
    handleLarge,
    itemUpdate,
    refetchTemplates,
  } = props;
  const [form] = Form.useForm();
  const { mutateAsync: postTemp, isLoading } = usePostTemplate();
  const { mutateAsync: updateTemplate, isLoading: loadingTemplate } =
    useUpdateTemplate();
  const { mutateAsync: getLargeSqure, isLoading: loadingLargeSquare } =
    useGetLargeSquare();

  const { mutateAsync: getBasicSquare, isLoading: loadingBasicSquare } =
    useGetBasicSquare();

  const initialValues = {
    title: itemUpdate ? itemUpdate.title : "",
    description: itemUpdate ? itemUpdate.description : "",
    large_square: itemUpdate ? itemUpdate.large_square : {},
    basic_square: itemUpdate ? itemUpdate.basic_square : {},
  };

  //! function

  const handleSubmit = async (values: any) => {
    try {
      if (!itemUpdate) {
        await postTemp(values, {
          onSuccess: () => {
            refetchTemplates && refetchTemplates();
            handleLarge();
            handleBasic();
            showSuccess(mesSuccess);
            handleClose();
          },
        });
      } else {
        await updateTemplate(
          { id: Number(itemUpdate?.id), body: values },
          {
            onSuccess: (res) => {
              handleLarge();
              handleBasic();
              refetchTemplates && refetchTemplates();
              showSuccess(res.data?.message ?? mesSuccess);
              handleClose();
            },
          }
        );
      }
    } catch (error) {
      showError(getErrorMsg(error));
    }
  };

  const handleLargeSquare = async () => {
    try {
      await getLargeSqure(
        {},
        {
          onSuccess: (value) => {
            const dataSet = value.data?.data;
            setField("large_square", dataSet);
            handleLarge(dataSet);
          },
        }
      );
    } catch (error) {
      showError(getErrorMsg(error));
    }
  };
  const handleLBasicSquare = async () => {
    try {
      await getBasicSquare(
        {},
        {
          onSuccess: (value) => {
            const dataSet = value.data?.data;
            setField("basic_square", dataSet);
            handleBasic(dataSet);
          },
        }
      );
    } catch (error) {
      showError(getErrorMsg(error));
    }
  };
  const setField = (key: string, value: any) => {
    form.setFieldValue(key, value);
  };

  useEffect(() => {
    if (_.isEmpty(itemUpdate)) {
      handleLarge();
      handleBasic();
    } else {
      handleBasic(itemUpdate?.basic_square);
      handleLarge(itemUpdate?.large_square);
    }
  }, [itemUpdate]);

  //! render
  return (
    <div className="col-span-1 border-r px-3 mt-3">
      <div className="flex justify-between">
        <CommonStyles.TypographyUI className="font-bold text-lg">
          {itemUpdate ? "Thông tin mẫu mã hoá" : "Thêm mới mẫu mã hoá"}
        </CommonStyles.TypographyUI>
      </div>

      <Divider />

      <div className="flex flex-col gap-y-2">
        <Form
          name="temp"
          form={form}
          layout="vertical"
          initialValues={initialValues}
          onFinish={handleSubmit}
        >
          <CommonStyles.FormFieldUI
            name="title"
            label="Tiêu đề"
            rules={[{ required: true, message: "Tiều đề không được trống!" }]}
            component={
              <CommonStyles.InputUI
                className="bg-gray-100"
                maxLength={50}
                placeholder={"Nhập tiêu đề"}
              />
            }
          />

          <CommonStyles.FormFieldUI
            name="large_square"
            rules={[{ validator: validateLarge }]}
            component={
              <div className="flex flex-col gap-5">
                <div className="flex justify-between items-center">
                  <CommonStyles.TypographyUI>
                    Ô vuông lớn
                  </CommonStyles.TypographyUI>
                  <CommonStyles.ButtonUI
                    loading={loadingLargeSquare}
                    onClick={() => {
                      handleLargeSquare();
                    }}
                    className="bg-gray-100 text-black px-10"
                  >
                    Thay đổi
                  </CommonStyles.ButtonUI>
                </div>
              </div>
            }
          />
          <CommonStyles.FormFieldUI
            name="basic_square"
            rules={[{ validator: validateBasic }]}
            component={
              <div>
                <div className="flex justify-between items-center">
                  <CommonStyles.TypographyUI>
                    Ô vuông cơ bản
                  </CommonStyles.TypographyUI>
                  <CommonStyles.ButtonUI
                    loading={loadingBasicSquare}
                    onClick={() => {
                      handleLBasicSquare();
                    }}
                    className="bg-gray-100 text-black px-10"
                  >
                    Thay đổi
                  </CommonStyles.ButtonUI>
                </div>
              </div>
            }
          />

          <CommonStyles.FormFieldUI
            name="description"
            rules={[{ required: true, message: "Mô tả không được để trống!" }]}
            label="Ghi chú"
            component={
              <CommonStyles.TextArea
                rows={7}
                maxLength={50}
                placeholder={"Nhập nội dung"}
                className="bg-gray-100"
              />
            }
          />
          <div className=" flex justify-end gap-3 pt-4">
            <CommonStyles.ButtonUI
              className="bg-gray-100 text-black px-10"
              onClick={() => {
                handleBasic();
                handleLarge();
                handleClose();
              }}
            >
              Hủy
            </CommonStyles.ButtonUI>

            <CommonStyles.ButtonUI
              action="submit"
              className="bg-black text-white px-10"
              loading={isLoading}
            >
              Lưu
            </CommonStyles.ButtonUI>
          </div>
        </Form>
      </div>
    </div>
  );
}
