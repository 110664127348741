import { API_CHAT } from "../constants/api";
import { parseQueryString } from "../helpers";
import httpService from "./httpService";

class ChatService {
  chat(parmas: any) {
    return httpService.post(`${API_CHAT}?${parseQueryString(parmas)}`);
  }
}

export default new ChatService();
