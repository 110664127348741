import { Card, Divider } from "antd";
import { ReactNode } from "react";
import TypographyUI from "../Typography/TypographyUI";

interface Iprops {
  children: ReactNode;
  title?: string;
  style?: any;
  extra?: ReactNode;
  height?: number;
}
export default function CardUI(props: Iprops) {
  //! state
  const { children, title, style, extra, height, ...res } = props;
  //! function
  //! render

  return (
    <div
      className="p-5 border"
      style={{
        borderRadius: "16px",
        backgroundColor: " rgba(255, 255, 255, 1)",
        height: height ?? "",
      }}
      {...res}
    >
      <div>
        <div className="flex justify-between">
          <TypographyUI typographyType="title" level={4}>
            {title}
          </TypographyUI>
          {extra && extra}
        </div>
        <Divider className="my-2" />
      </div>

      {children}
    </div>
  );
}
