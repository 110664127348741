import { Input } from "antd";

const { TextArea } = Input;
interface IProps {
  placeholder?: string;
  rows?: number;
  autoSize?: boolean;
  maxLength?: number;
  minLength?: number;
  className?: string;
  disabled?: boolean;
}
export default function TextAreaUI(props: IProps) {
  //! state
  const {
    placeholder,
    rows,
    autoSize,
    maxLength,
    minLength,
    className,
    disabled,
    ...res
  } = props;
  //! function
  //! render
  return (
    <TextArea
      rows={rows}
      placeholder={placeholder}
      maxLength={maxLength}
      minLength={minLength}
      autoSize={autoSize}
      className={className}
      style={{ resize: "none" }}
      {...res}
    />
  );
}
