import { Button } from "antd";

interface IProps {
  Icons?: any;
  onClick?: () => void;
  children?: any;
  className?: string;
  danger?: boolean;
  disabled?: boolean;
  ghost?: boolean;
  href?: string;
  type?: "primary" | "dashed" | "link" | "text" | "default";
  loading?: boolean;
  shape?: "default" | "circle" | "round";
  size?: "large" | "middle" | "small";
  styles?: any;
  action?: "button" | "reset" | "submit";
  onKeyDown?: React.KeyboardEventHandler<HTMLElement>;
}

export default function ButtonUI(props: IProps) {
  const {
    children,
    onClick,
    Icons,
    styles,
    className,
    danger,
    disabled,
    ghost,
    href,
    loading,
    shape,
    size,
    type,
    action,
    onKeyDown,
    ...res
  } = props;
  return (
    <Button
      // className={className ? className : "text-white bg-main-color"}
      className={`xs:text-xs sm:text-sm cursor-pointer hover:bg-transparent ${className}`}
      type={type ?? "primary"}
      htmlType={action}
      icon={Icons ? Icons : null}
      danger={danger}
      disabled={disabled}
      ghost={ghost}
      href={href}
      loading={loading}
      shape={shape}
      size={size}
      style={styles}
      {...res}
      onClick={onClick}
      onKeyDown={onKeyDown}
    >
      {children}
    </Button>
  );
}
