import React, { useState, useEffect } from "react";
import { Layout, theme } from "antd";
// import SiderMenu from "./default/SiderMenu";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import OutsideClickHandler from "react-outside-click-handler";
import Navbar from "../../components/UI/Navbar";
import SiderMenu from "../../components/UI/Sider";
import CommonIcons from "../../assets/icons";
import CommonImages from "../../assets/images";

const { Header, Sider, Content } = Layout;

const DefaultLayout: React.FC = () => {
  //! state
  const [breadcrumbs, setBreadcrumb] = useState<Array<Object>>([]);
  const [collapsed, setCollapsed] = useState(false);
  const [documentWidth, setDocumentWidth] = useState<number>(
    document.documentElement.clientWidth ?? 0
  );
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  let location = useLocation();
  const navigate = useNavigate();
  //! function
  useEffect(() => {
    setBreadcrumb([{ title: location.pathname.replace("/", "") }]);
  }, [location]);
  useEffect(() => {
    window.addEventListener("resize", onResize, { passive: true });
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  const onResize = () => {
    setDocumentWidth(document.documentElement.clientWidth);
  };

  const onClickOutSideSider = () => {
    if ((documentWidth ? documentWidth : 0) <= 1024 && !collapsed) {
      setCollapsed(true);
    }
  };

  return (
    <Layout>
      <OutsideClickHandler onOutsideClick={() => onClickOutSideSider()}>
        <Sider
          breakpoint="lg"
          onCollapse={(collapsed, type) => {}}
          collapsible={false}
          collapsed={collapsed}
          trigger={null}
          width={240}
          // collapsedWidth={(documentWidth ? documentWidth : 0) <= 1024 ? 0 : 64}
          className={
            "h-screen !bg-white border-r " +
            ((documentWidth ? documentWidth : 0) <= 1024
              ? !collapsed
                ? " !fixed top-0 bottom-0 z-1030"
                : " "
              : "")
          }
        >
          <div
            className={
              "flex items-center justify-center py-3 text-xl" +
              (collapsed ? " text-center" : " px-2")
            }
          >
            <div
              className={collapsed ? "cursor-pointer " : "mr-3 cursor-pointer"}
              onClick={() => {
                setCollapsed(!collapsed);
              }}
            >
              <CommonIcons.MenuOutlined />
            </div>
            <div className="flex justify-center items-center gap-2">
              <img
                className={
                  "h-7 w-7" + (collapsed ? " hidden duration-300" : "")
                }
                src={`${CommonImages.logo}`}
              />

              <p
                className={
                  "text-xl text-black w-full font-bold" +
                  (collapsed ? " hidden duration-300" : "")
                }
                style={{ fontFamily: "GoogleSans", fontWeight: "bold" }}
              >
                Airforce Monitor
              </p>
            </div>
          </div>
          <SiderMenu collapsed={collapsed} />
        </Sider>
      </OutsideClickHandler>

      <Layout
        className={
          "overflow-y-auto bg-white" +
          ((documentWidth ? documentWidth : 0) > 1024
            ? " h-screen"
            : " h-screen")
        }
      >
        <Content className={""}>
          <Outlet></Outlet>
        </Content>
      </Layout>
    </Layout>
  );
};

export default DefaultLayout;
