import { Menu } from "antd";
import type { MenuProps } from "antd";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import CommonImages from "../../../assets/images";
import { useEffect, useState } from "react";

const menu = [
  {
    label: "Bản đồ",
    key: "maps",
    icon: <img src={`${CommonImages.map}`} />,
    iconactive: <img src={`${CommonImages.mapActive}`} />,
    className: "!m-0 !rounded-none !w-full !text black",
  },
  {
    label: "Mẫu mã hoá",
    key: "form",
    icon: <img src={`${CommonImages.templa}`} />,
    iconactive: <img src={`${CommonImages.templaActive}`} />,
    className: "!m-0 !rounded-none !w-full !text black",
  },
];
export default function SiderMenu(props: any) {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedKey, setSelectedKey] = useState(
    location.pathname.split("/")[1]
  );

  const onClick: MenuProps["onClick"] = (e: any) => {
    navigate("/" + e?.key);
    setSelectedKey(e?.key);
  };

  return (
    <Menu
      // className="!bg-white !text-black !border-none"
      mode="inline"
      defaultSelectedKeys={["maps"]}
      selectedKeys={[selectedKey]}
      // defaultOpenKeys={["maps"]}
      onClick={(e) => onClick(e)}
      items={menu.map((item) => {
        return {
          ...item,
          icon: selectedKey == item.key ? item.iconactive : item.icon,
          className: `${item.className} ${
            selectedKey == item.key ? "!bg-gray-200" : " !text-black"
          }`,
        };
      })}
    />
  );
}
