import { useMutation } from "react-query";
import templateService from "../../services/templateService";

interface IUpdate {
  id: number;
  body: {
    title: string;
    large_square: any;
    basic_square: any;
    description: string;
  };
}

export const useUpdateTemplate = () => {
  return useMutation({
    mutationFn: ({ id, body }: IUpdate) =>
      templateService.updateTemplate(id, body),
  });
};

export default useUpdateTemplate;
