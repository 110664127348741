export const scrollUI = (tag: any) => {
  tag?.scrollIntoView({
    behavior: "smooth",
    block: "end",
  });
};
export const scrollToLastMessage = (chatContainerRef: any) => {
  const chatContainer = chatContainerRef.current;
  if (chatContainer) {
    chatContainer.lastChild?.scrollIntoView({ behavior: "smooth" });
  }
};

export const parseQueryString = (query: Object) => {
  return Object.entries(query)
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join("&");
};

export const getErrorMsg = (error: any) => {
  if (error?.response?.data?.error?.message) {
    return error.response.data.error.message;
  }
  if (error?.response?.data?.[0]) {
    return error?.response?.data?.[0];
  }
  if (error?.response?.data?.message) {
    return error?.response?.data?.message;
  }

  if (error?.response?.data?.detail) {
    return error?.response?.data?.detail;
  }

  return `Something wrong!`;
};
