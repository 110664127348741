import { Select, SelectProps, Space } from "antd";
import "./Select.css";
import { TOption } from "../../../interfaces";
interface IProps {
  disabled?: boolean;
  loading?: boolean;
  options: TOption[];
  hanleChange?: SelectProps["onChange"];
  className?: string;
  value?: string | number;
  variant?: "outlined" | "borderless" | "filled";
  handleSelect?: SelectProps["onSelect"];
  afterOnchange?: () => void;
}
export default function SelectUI(props: IProps) {
  //! state
  const {
    disabled,
    loading,
    options,
    hanleChange,
    className,
    value,
    handleSelect,
    variant,
    afterOnchange,
    ...res
  } = props;
  //! function
  const onChangeOptions = (value: any, option: any) => {
    if (afterOnchange) {
      afterOnchange();
    }
  };
  //! render
  return (
    <Select
      onChange={hanleChange}
      options={options}
      value={value}
      variant={variant}
      className={`m-w-10 ${className}`}
      onSelect={onChangeOptions}
      optionRender={(option) => <Space>{option.label}</Space>}
      {...res}
    />
  );
}
