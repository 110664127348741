import {
  MenuOutlined,
  PlusOutlined,
  LeftOutlined,
  AudioOutlined,
  MoreOutlined,
  DeleteOutlined,
  EditOutlined,
  WarningOutlined,
} from "@ant-design/icons";
const CommonIcons = {
  MenuOutlined: MenuOutlined,
  PlusOutlined: PlusOutlined,
  LeftOutlined: LeftOutlined,
  AudioOutlined: AudioOutlined,
  MoreOutlined: MoreOutlined,
  DeleteOutlined: DeleteOutlined,
  EditOutlined: EditOutlined,
  WarningOutlined: WarningOutlined,
};

export default CommonIcons;
