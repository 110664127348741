import { useEffect, useRef, useState } from "react";
import CommonStyles from "../../UI";
import CommonImages from "../../../assets/images";
import CommonIcons from "../../../assets/icons";
import { dataChatBot } from "../../../mocks";
import { getErrorMsg, scrollToLastMessage } from "../../../helpers";
import { useReactMediaRecorder } from "react-media-recorder-2";
import useSpeech2Text from "../../../hooks/useSpeech2Text/useSpeech2Text";
import useToggle from "../../../hooks/useToggle";
import { Space } from "antd";
import { showError } from "../../../helpers/toast";
import useChat from "../../../hooks/useChat/useChat";
import styles from "./chatBot.module.css";
import { useParams } from "react-router-dom";

interface IProps {
  setPolylines: any;
}
export default function Chatbot(props: IProps) {
  //! state
  const { setPolylines } = props;
  const [dataBot, setDataBot] = useState<{ id: number; text: string }[]>([]);
  const [valueText, setValueText] = useState("");
  const messagesEndRef = useRef<null | HTMLDivElement>(null);
  const parmas = useParams();
  const { open, shouldRender, toggle } = useToggle();

  const { status, startRecording, stopRecording } = useReactMediaRecorder({
    video: false,
    audio: true,
    onStop: async (blobUrl, blob) => {
      handleStopMic(blob);
    },
  });

  const { mutateAsync: speechMutate, isLoading: loadingS2T } = useSpeech2Text();
  const { mutateAsync: chat, isLoading: loadingChat } = useChat();
  //! function
  useEffect(() => {
    setDataBot(dataChatBot);
  }, []);

  useEffect(() => {
    scrollToLastMessage(messagesEndRef);
  }, [loadingChat]);

  const handleChangeText = (e: any) => {
    setValueText(e.target.value);
  };

  const onKeyUp = async (e: any) => {
    if (e.keyCode === 13) {
      const chatUser = {
        id: 2,
        text: valueText,
      };
      const botResponse = {
        id: 1,
        text: "...",
      };
      const dataApi = {
        input: valueText,
        airforce_id: parmas?.id,
      };
      const dataChat = [...dataBot];
      dataChat.push(chatUser);
      dataChat.push(botResponse);
      setDataBot(dataChat);

      try {
        setValueText("");
        await chat(dataApi, {
          onSuccess: (res) => {
            const dataChatFinall = [...dataChat];
            if (res.data.detail) {
              dataChatFinall[dataChatFinall.length - 1].text = res.data?.detail;
              setDataBot(dataChatFinall);
              return;
            }
            setPolylines(res.data?.data?.route);
            dataChatFinall[dataChatFinall.length - 1].text = res.data?.message;

            setDataBot(dataChatFinall);
          },
        });
      } catch (error) {
        showError(getErrorMsg(error));
      }
    }
  };
  const StatusRecording = status === "recording";

  const handleMic = async () => {
    if (!loadingChat) {
      startRecording();
      toggle();
    }
  };

  const handleStopMic = async (blob: Blob) => {
    try {
      const formData = new FormData();
      const audioFile = new File([blob], "voice.wav", {
        type: "audio/wav",
      });
      formData.append("audio", audioFile);
      const res = await speechMutate(formData);
      setValueText(res?.data?.transcription);
    } catch (error) {
      showError(getErrorMsg(error));
    }
  };

  //! render
  const suffix = (
    <CommonStyles.TooltipUI title={StatusRecording ? "Stop" : "Record"}>
      <CommonIcons.AudioOutlined
        onClick={handleMic}
        style={{
          fontSize: 16,
          color: StatusRecording ? "blue" : "#000",
        }}
      />
    </CommonStyles.TooltipUI>
  );

  const Bot = (item: any) => {
    return (
      <div className="flex items-center gap-3 row">
        <div
          className="border rounded-full text-center w-7 h-7"
          style={{ background: "rgba(235, 237, 238, 1)" }}
        >
          <img className="h-full" src={`${CommonImages.bot}`} alt="bot" />
        </div>

        <div
          className={"p-3 text-white bg-black  rounded-md w-64"}
          style={{ whiteSpace: "pre-line" }}
        >
          {item.item.text === "..." ? (
            <div className={styles.loader}></div>
          ) : (
            item.item.text
          )}
        </div>
      </div>
    );
  };

  const UserChat = (item: any) => {
    return (
      <div className="flex items-center gap-3 flex-row-reverse" id="row">
        <div
          className="border rounded-full text-center w-7 h-7"
          style={{ background: "rgba(235, 237, 238, 1)" }}
        >
          <img className="h-full" src={`${CommonImages.user}`} alt="user" />
        </div>

        <div
          className="p-2 rounded-md w-64 break-words text-end"
          style={{ background: "rgba(235, 237, 238, 1)" }}
        >
          {item.item.text}
        </div>
      </div>
    );
  };

  const renderChat = () => {
    return dataBot?.map((item, index) => {
      if (item.id === 1) {
        return <Bot item={item} key={index} />;
      }
      return <UserChat item={item} key={index} />;
    });
  };

  return (
    <CommonStyles.CardUI title="Chatbot" height={450}>
      <div className="flex flex-col justify-between ">
        <div
          className="flex flex-col gap-4"
          style={{ height: 300, overflowY: "scroll" }}
          ref={messagesEndRef}
        >
          {renderChat()}
        </div>
      </div>

      <div className="absolute w-11/12 bottom-5 text-center ">
        <CommonStyles.InputUI
          placeholder="Nhập nội dung..."
          variant="filled"
          suffix={suffix}
          onKeyUp={onKeyUp}
          value={valueText}
          onChange={handleChangeText}
          readOnly={loadingS2T || loadingChat}
        />
      </div>

      {shouldRender && (
        <CommonStyles.ModalUI toggle={toggle} open={open} hiddenAction>
          <CommonStyles.TypographyUI className="block text-center">
            Recording ...
          </CommonStyles.TypographyUI>

          <Space className="flex justify-center">
            <CommonStyles.ButtonUI
              onClick={() => {
                stopRecording();
                toggle();
              }}
              ghost
              danger
            >
              Stop
            </CommonStyles.ButtonUI>
          </Space>
        </CommonStyles.ModalUI>
      )}
    </CommonStyles.CardUI>
  );
}
